import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import i18n from '../configs/i18n';
import ThemeProvider from '../themeProvidere/themeProvider';
import {templateComponents} from '../configs/mainConfig';

function BonusesList({
  deviceType,
  templateType,
  customizationTheme,
  translations,
  language,
  bonusesList,
  simplifiedData,
  goToPaymentsList,
  setSelectedBonusHandler,
  navigateToBonusInfo,
  handlePaymentSystemDetailsPage
}) {
  useEffect(() => {
    if (language && Object.keys(translations)) {
      i18n.addResourceBundle(language, 'translation', translations, true, true);
      i18n.changeLanguage(language);
    }
  }, [language, translations]);

  const DynamicComponent = templateComponents[templateType]?.[deviceType]?.BonusesListComponent;

  if (DynamicComponent) {
    return (
      <ThemeProvider theme={customizationTheme}>
        <DynamicComponent
          bonusesList={bonusesList}
          simplifiedData={simplifiedData}
          goToPaymentsList={goToPaymentsList}
          setSelectedBonusHandler={setSelectedBonusHandler}
          navigateToBonusInfo={navigateToBonusInfo}
          handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
        />
      </ThemeProvider>
    );
  }

  return null;
}

BonusesList.defaultProps = {
  deviceType: null,
  templateType: null,
  customizationTheme: null,
  translations: {},
  language: 'en'
};

BonusesList.propTypes = {
  deviceType: PropTypes.number,
  templateType: PropTypes.number,
  customizationTheme: PropTypes.object,
  translations: PropTypes.object,
  language: PropTypes.string,
  bonusesList: PropTypes.array.isRequired,
  simplifiedData: PropTypes.array.isRequired,
  goToPaymentsList: PropTypes.func.isRequired,
  setSelectedBonusHandler: PropTypes.func.isRequired,
  navigateToBonusInfo: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default memo(BonusesList);
