import {css} from 'styled-components';
import {CONTROL_STATE, HOVER_MOTIONS_LIST} from '../constants/common';
import {defaultTheme} from '../theme/theme';
import {StyledPaymentFormWrapperMobile} from '../components/paymentsForm/templateOne/mobile/formMobile.styled';
import {StyledPaymentFormWrapper} from '../components/paymentsForm/templateOne/web/formWeb.styled';

/**
 * Define flex style for elements
 *
 * General style for all flex elements
 */
export function flexContainer() {
  return css`
    display: flex;
    justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'center')};
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
    flex-direction: ${(props) => props.flexDirection};
  `;
}

/**
 * Define flex column style for elements
 *
 * General style for all flex column elements
 */
export function flexColumnContainer() {
  return css`
    display: flex;
    flex-direction: column;
  `;
}

/**
 * Define disable style for elements
 *
 * If the button is disabled, these styles are added
 */

export function disable() {
  return css`
    opacity: ${(props) => (props.disabled ? '0.6' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
  `;
}

/**
 * Creates text truncation CSS props.
 *
 * If text is not fitting in block, three dots will be added in the end.
 */
export function truncate() {
  return css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `;
}

/**
 * Define font style for elements
 *
 * @param {String} type
 *
 */
export function fontStyle(type) {
  switch (type) {
    case 'regular':
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightRegular};
        font-size: 14px;
        line-height: 24px;
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
    case 'medium':
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightMedium};
        font-size: 14px;
        line-height: 24px;
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
    case 'semiBold':
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightSemiBold};
        font-size: 14px;
        line-height: 24px;
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
    default:
      return css`
        font-weight: ${(props) => props.theme.typographyFontWeightRegular};
        font-size: ${(props) => props.theme.typographyFontSizeExtraSmall};
        font-family: ${(props) => props.theme.typographyFontFamilyBase};
      `;
  }
}

export function generateStatusMessageFieldColor(state, opacity) {
  switch (state) {
    case CONTROL_STATE.IN_PROCESS:
      return `rgba(0, 106, 220, ${opacity})`;
    case CONTROL_STATE.FAIL:
      return `rgba(205, 43, 49, ${opacity})`;
    case CONTROL_STATE.SUCCESS:
      return `rgba(8, 157, 0, ${opacity})`;
    default:
      return 'background: transparent';
  }
}

export const generateStatusImage = (state) => {
  switch (state) {
    case CONTROL_STATE.IN_PROCESS:
      return 'info';
    case CONTROL_STATE.FAIL:
      return 'error';
    case CONTROL_STATE.SUCCESS:
      return 'success';
    default:
      return null;
  }
};

export const getCardHoverStyle = (cardHoverMotion) => {
  if (cardHoverMotion === HOVER_MOTIONS_LIST.ZOOM) {
    return {
      transform: 'scale(1.1)'
    };
  } if (cardHoverMotion === HOVER_MOTIONS_LIST.BUTTON) {
    return {
      background: 'red'
    };
  }
  return null;
};

// move this function into helpers
export const mergedTheme = (customTheme) => {
  const modifiedCustomTheme = {...customTheme};

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(customTheme)) {
    if (typeof value === 'object' && value !== null) {
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        const newKey = `${key}${nestedKey}`;
        modifiedCustomTheme[newKey] = nestedValue;
      }
      // remove the top-level key to avoid duplication
      delete modifiedCustomTheme[key];
    }
  }

  return {...defaultTheme, ...modifiedCustomTheme};
};
const removeHash = (color) => color.replace(/^#/, '');
export const generateBgImageUrl = (props, dashArray, dashArraySecondValue, dashoffset) => `data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${removeHash(props.theme.mainButtonBackgroundColor)}' stroke-width='3' stroke-dasharray='${dashArray}%2c ${dashArraySecondValue}' stroke-dashoffset='${dashoffset}' stroke-linecap='square'/%3e%3c/svg%3e`;

export const customStyles = (theme) => ({
  control: (provided, state) => ({
    ...provided,
    color: theme.inputTextColor,
    backgroundColor: theme.inputBackgroundColor,
    borderRadius: `
      ${theme.inputCornerRadiusTopLeft || 0}px
      ${theme.inputCornerRadiusTopRight || 0}px
      ${theme.inputCornerRadiusBottomRight || 0}px
      ${theme.inputCornerRadiusBottomLeft || 0}px
    `,
    border: `${theme.inputHasBorder
      ? `${theme.inputBorderWidth} solid ${theme.inputBorderColor}`
      : theme.defaultInputBorderWidth}`,
    borderTop: theme.inputBorderTop,
    borderColor: theme.inputBorderColor,
    borderBottom: theme.inputBorderBottom,
    borderStyle: theme.inputBorderStyle,
    height: 36,
    minHeight: 'auto',
    opacity: state.isDisabled ? '0.4' : '1',
    cursor: state.isDisabled ? 'unset' : 'pointer',
    outline: state.isFocused && '0',
    boxShadow: 'unset',
    transition: 0,

    '&:hover': {
      transition: 'background-color 800ms',
      border: `1px solid ${theme.mainButtonBackgroundColor}`,
      boxShadow: 'none'
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.inputTextColor,
    font: '400 14px Roboto, sans-serif',
    paddingLeft: '0',
    marginLeft: '0'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `${theme.inputTextColor}70`,
    font: '400 14px Roboto, sans-serif'
  }),
  menu: () => ({
    position: 'absolute',
    width: '100%',
    marginTop: '2px',
    backgroundColor: theme.inputBackgroundColor,
    color: theme.inputTextColor,
    borderTopLeftRadius: `${theme.inputCornerRadiusTopLeft}px`,
    borderTopRightRadius: `${theme.inputCornerRadiusTopRight}px`,
    borderBottomLeftRadius: `${theme.inputCornerRadiusBottomLeft}px`,
    borderBottomRightRadius: `${theme.inputCornerRadiusBottomRight}px`,
    boxShadow: '0 3px 6px #7d7d7d10',
    zIndex: theme.zIndex9999,
    overflow: 'hidden'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: state.isSelected ? theme.mainButtonBackgroundColor : theme.inputTextColor,

    '&:hover': {
      backgroundColor: `${theme.inputTextColor}20`,
      color: state.isSelected ? theme.mainButtonBackgroundColor : theme.inputTextColor
    }
  }),
  dropdownIndicator: () => ({
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
    color: theme.inputTextColor
  }),
  input: (provided) => ({
    ...provided,
    color: theme.inputTextColor,
    caretColor: theme.inputTextColor,
    marginLeft: '0',
    font: '400 14px Roboto, sans-serif'
  })
});

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getBorderRadius = (props, buttonType = 'mainButton') => `
  ${props.theme[`${buttonType}CornerRadiusTopLeft`] || 0}px 
  ${props.theme[`${buttonType}CornerRadiusTopRight`] || 0}px 
  ${props.theme[`${buttonType}CornerRadiusBottomRight`] || 0}px 
  ${props.theme[`${buttonType}CornerRadiusBottomLeft`] || 0}px
`;

export const getBorder = (props, buttonType = 'mainButton') => {
  const borderWidth = props.theme[`${buttonType}HasBorder`]
    ? `${props.theme[`${buttonType}BorderWidth`]}px`
    : `${props.theme[`default${capitalize(buttonType)}BorderWidth`]}px`;

  const borderColor = props.theme[`${buttonType}HasBorder`]
    ? props.theme[`${buttonType}BorderColor`]
    : 'transparent'; // or a fallback value if needed

  return `${borderWidth} solid ${borderColor}`;
};

export const StyledWrapperMapping = {
  1: StyledPaymentFormWrapper,
  2: StyledPaymentFormWrapperMobile
};
