import instance from './apiService';
import Config from '../configs/mainConfig';
import {groupBonusesByPaymentsId} from '../helpers/helperFunction';

export const getMerchantUserBonuses = ({
  siteId,
  userId,
  currency,
  country,
  setBonusData,
  setIsLoading
}) => {
  setIsLoading(true);
  instance.get(
    `${Config.apiURLs.getMerchantUserBonuses}/${siteId}/${userId}/${country}/${currency}`,
    {
      param: {
        siteId,
        userId,
        country,
        currency
      }
    }
  )
    .then((response) => {
      if (response?.status === 200) {
        const {data} = response;
        setBonusData({bonusesList: data, bonusesGroupedByPayment: groupBonusesByPaymentsId(data)});
      }
    })
    .finally(() => setIsLoading(false));
};
