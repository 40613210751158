import React from 'react';
import PropTypes from 'prop-types';
import BonusItem from './bonusItem';
import {generatePaymentDataForBonusList} from '../../../../helpers/common';

function BonusesListWeb({
  bonusesList,
  simplifiedData,
  goToPaymentsList,
  setSelectedBonusHandler,
  navigateToBonusInfo,
  handlePaymentSystemDetailsPage
}) {
  return (
    <div>
      <button type="button" onClick={goToPaymentsList}>back to payment list</button>
      <h1>WEB</h1>
      <div style={{display: 'flex'}}>
        {bonusesList.map(({
          id, webImage, shortDescription, name, paymentSystems
        }) => (
          <BonusItem
            key={id}
            id={id}
            imageUrl={webImage}
            name={name}
            shortDescription={shortDescription}
            paymentSystems={generatePaymentDataForBonusList(paymentSystems, simplifiedData)}
            setSelectedBonusHandler={setSelectedBonusHandler}
            navigateToBonusInfo={navigateToBonusInfo}
            handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
          />
        ))}
      </div>
    </div>
  );
}

BonusesListWeb.propTypes = {
  bonusesList: PropTypes.array.isRequired,
  simplifiedData: PropTypes.array.isRequired,
  goToPaymentsList: PropTypes.func.isRequired,
  setSelectedBonusHandler: PropTypes.func.isRequired,
  navigateToBonusInfo: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default BonusesListWeb;
