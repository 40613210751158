import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {
  formatDate,
  getCurrencySymbolOrCode
} from '../../../../helpers/common';

/* Styled Components */
import StyledHistoryList, {
  StyledCycle,
  StyledHistoryListItem,
  StyledHistoryListItemInfoWrapper,
  StyledHistoryListItemTitle,
  StyledHistoryListItemValue,
  StyledViewWrapper
} from './historyWeb.styled';
import {CopyIcon} from '../../../../assets/icons';
import {StyledButtonWithoutBg} from '../../../../styledComponents/button/button.styled';

function HistoryTabContentWeb({
  historyData,
  getSelectedHistoryDetails,
  isCurrencySymbol,
  setCurrency,
  isSafetyPay
}) {
  const {t} = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const handleDetailsInfo = (detailData) => {
    setSelectedItem(detailData);
    setCurrency(detailData?.currencyISO);
    getSelectedHistoryDetails(detailData?.data);
  };
  const copyHandler = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <StyledHistoryList>
      {historyData?.map((historyItem) => (
        <StyledHistoryListItem
          key={historyItem?.transactionId}
          selectedItem={selectedItem?.transactionId === historyItem?.transactionId}
          onClick={() => handleDetailsInfo(historyItem)}
        >
          <StyledHistoryListItemInfoWrapper>
            <StyledHistoryListItemTitle>
              {t((isSafetyPay ? 'orderId' : 'transactionId')?.toLowerCase())}
              :
              {isSafetyPay ? (
                <StyledHistoryListItemValue>
                  {historyItem?.data?.find((item) => item?.dataName === 'OrderID')?.data}
                  {historyItem?.data?.find((item) => item?.dataName === 'OrderID')?.hasCopy ? (
                    <StyledButtonWithoutBg onClick={() => copyHandler(historyItem?.data?.find((item) => item?.dataName === 'OrderID')?.data)}>
                      <CopyIcon />
                    </StyledButtonWithoutBg>
                  ) : null}
                </StyledHistoryListItemValue>
              ) : (
                <StyledHistoryListItemValue>
                  {historyItem?.transactionId}
                </StyledHistoryListItemValue>
              )}
            </StyledHistoryListItemTitle>
            <StyledCycle />
            <StyledHistoryListItemTitle>
              {t(('date')?.toLowerCase())}
              :
              <StyledHistoryListItemValue>
                {formatDate(historyItem?.date)}
              </StyledHistoryListItemValue>
            </StyledHistoryListItemTitle>
            <StyledCycle />
            <StyledHistoryListItemTitle>
              {t(('Amount')?.toLowerCase())}
              :
              {' '}
              <StyledHistoryListItemValue>
                {historyItem?.amount}
                {' '}
                {getCurrencySymbolOrCode(historyItem?.currencyISO, isCurrencySymbol)}
              </StyledHistoryListItemValue>
            </StyledHistoryListItemTitle>
          </StyledHistoryListItemInfoWrapper>
          <StyledViewWrapper>
            <img src="https://cdn-payments.apidigi.com/Cashire/images/eye.svg" alt="Eye" />
          </StyledViewWrapper>
        </StyledHistoryListItem>
      ))}
    </StyledHistoryList>
  );
}

HistoryTabContentWeb.defaultProps = {
  historyData: null,
  getSelectedHistoryDetails: null,
  isCurrencySymbol: null,
  isSafetyPay: false
};

HistoryTabContentWeb.propTypes = {
  historyData: PropTypes.array,
  getSelectedHistoryDetails: PropTypes.func,
  isCurrencySymbol: PropTypes.bool,
  isSafetyPay: PropTypes.bool,
  setCurrency: PropTypes.func.isRequired
};

export default HistoryTabContentWeb;
