import instance from './apiService';
import Config from '../configs/mainConfig';

const {
  baseURL,
  merchantVerificationHistory
} = Config.apiURLs;

export const getVerificationHistory = ({
  siteId,
  userId,
  paymentSystemId,
  setVerificationHistory
}) => instance.get(
  `${baseURL}${merchantVerificationHistory}/${siteId}/${userId}/${paymentSystemId}`,
  {
    param: {
      siteId,
      userId,
      paymentSystemId
    }
  }
)
  .then((res) => setVerificationHistory(res.data));
