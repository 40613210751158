export const PAYMENT_CONTROL_TYPE = {
  LABEL: 2,
  TEXT_BOX: 3,
  RADIO: 4, //
  CHECKBOX: 5,
  SELECT: 6,
  AMOUNT_PICKER: 11, //
  BUTTON: 12,
  HIDDEN: 14, //
  QRCODE: 15,
  DYNAMIC_DROPDOWN: 17,
  SAVE_AND_SHOW_CARD_DATA: 18, // POPUP
  BANK_AMOUNT_LIST: 19,
  BUTTON_WIDTH_URL: 20,
  STATUS_MESSAGE: 21,
  APPLE_PAY_BUTTON: 23,
  BUTTON_WITH_MULTI_URL: 24,
  DYNAMIC_LABEL: 25,
  PAYPAL_BUTTON: 27,
  PAYPAL_CREDIT_DEBIT_CARD: 28,
  GOOGLE_PAY_BUTTON: 29,
  NEW_DYNAMIC_LIST: 30,
  NEW_STATIC_LIST: 31,
  UPLOAD_DOCUMENT: 32,
  SAFETY_PAY_GUIDE_AND_BUTTON: 38,
  DOUBLE_FIELD: 39,
  BACK_TO_FORM: 999,
  PAYMENT_BONUS_FIELD: 1000
};
