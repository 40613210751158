import React, {memo} from 'react';
import PropTypes from 'prop-types';

function BonusBanner({
  bannerUrl,
  goToBonusHandler
}) {
  return (
    <button type="button" onClick={goToBonusHandler}>
      <img src={bannerUrl} alt="banner" />
    </button>
  );
}

BonusBanner.propTypes = {
  bannerUrl: PropTypes.string,
  goToBonusHandler: PropTypes.func.isRequired
};

BonusBanner.defaultProps = {
  bannerUrl: null
};

export default memo(BonusBanner);
