import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import BonusPaymentItem from '../../../bonusPaymentItem/bonusPaymentItem';
import BonusSecondaryPaymentSystems from '../../../bonusSecondaryPaymentSystems/bonusSecondaryPaymentSystems';

function BonusItem({
  id,
  imageUrl,
  name,
  shortDescription,
  paymentSystems,
  setSelectedBonusHandler,
  navigateToBonusInfo,
  handlePaymentSystemDetailsPage
}) {
  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedBonusHandler(id);
    navigateToBonusInfo();
  };

  const onPaymentClickHandler = useCallback((e, {
    disableMethod,
    paymentSystemId,
    unavailable,
    hasRedirect,
    hasVerifiedAccount
  }) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedBonusHandler(id);
    handlePaymentSystemDetailsPage({
      disableMethod,
      paymentSystemId,
      unavailable,
      hasRedirect,
      hasVerifiedAccount
    });
  }, []);

  const [initialPayments, secondaryPayments] = useMemo(() => {
    if (paymentSystems.length <= 7) return [paymentSystems, null];

    return [paymentSystems.slice(0, 6), paymentSystems.slice(6, paymentSystems.length)];
  }, [paymentSystems]);

  return (
    <div
      style={{
        width: '400px', height: '250px', boxSizing: 'border-box', border: '1px solid black', padding: '10px', position: 'relative'
      }}
    >
      <div
        onClick={onClickHandler}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <div>
          <img src={imageUrl} alt="" />
        </div>
        <h3>{name}</h3>
        <h5>{shortDescription}</h5>
      </div>
      <div style={{
        display: 'flex',
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        zIndex: 100
      }}
      >
        {/* eslint-disable-next-line no-shadow */}
        {initialPayments.map((payment) => <BonusPaymentItem key={payment.id} {...payment} onPaymentClickHandler={onPaymentClickHandler} />)}
        {secondaryPayments?.length ? (
          <BonusSecondaryPaymentSystems secondaryPayments={secondaryPayments} onPaymentClickHandler={onPaymentClickHandler} />
        ) : null}
      </div>
    </div>
  );
}

BonusItem.propTypes = {
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  paymentSystems: PropTypes.array.isRequired,
  setSelectedBonusHandler: PropTypes.func.isRequired,
  navigateToBonusInfo: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default memo(BonusItem);
