import styled, {css} from 'styled-components';
import {
  disable,
  flexContainer,
  fontStyle,
  getBorder,
  getBorderRadius
} from '../../helpers/styled';
import {MOBILE_SIZE} from '../../constants/common';

const StyledButton = styled.button`
  width: 100%;
  min-height: 36px;
  height: auto;
  border: ${(props) => getBorder(props, 'mainButton')}; // Automatically handles border width, color, and style
  border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners
  background-color: ${({theme}) => theme.mainButtonBackgroundColor};
  color: ${({theme}) => theme.mainButtonTextColor};
  text-transform: uppercase;
  ${fontStyle('semiBold')};
  cursor: pointer;

  &:disabled {
    ${disable(0.6)};
  }
  
  ${({secondary}) => secondary && css`
    background-color: transparent;
    border: 1px solid ${({theme}) => theme.mainButtonBackgroundColor};
    color: ${({theme}) => theme.mainButtonBackgroundColor};
  `}
  
  ${({isMobile, secondary}) => isMobile && secondary && css` 
    width: 100px;
  `}
`;

const StyledButtonWithBorder = styled.button`
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.favoriteButtonTextColor};
  background-color: ${({theme}) => theme.favoriteButtonBackgroundColor};
  
  svg path {
    fill: ${(props) => props.theme.favoriteButtonTextColor};
  }
`;

const StyledButtonWithoutBg = styled.button`
  ${flexContainer()};
  background-color: unset;
  border: 0;
  color: ${({theme}) => theme.mainButtonTextColor};
  
  svg path {
    fill: ${({theme}) => theme.generalIconsColor};
  }

  ${(props) => props.isPositionLeft && css`
    position: absolute;
    left: 12px;
  `}
  
  ${(props) => props.isPositionRight && css`
    position: absolute;
    right: 12px;
    left: unset;
  `}
`;

const StyledLinkButton = styled.button`
  align-self: flex-end;
  ${fontStyle('semiBold')};
  font-size: ${(props) => props.theme.typographyFontSizeMiddle};
  line-height: 24px;
  background-color: unset;
  color: ${(props) => props.theme.textButtonBackgroundColor};
  border: 0;
  margin-bottom: 24px;

  ${(props) => props.centerAligned && css`
    align-self: center;
    text-decoration: underline;
    margin-top: ${() => (props.withoutMarginTop ? '0' : '24px')};
  `}

  ${(props) => props.centerAlignedWithinHorizontalLines && css`
    position: absolute;
    align-self: center;
    margin: 0;
    padding: 4px 16px;
    background-color: ${({theme}) => theme.generalCardBackgroundColor};
  `}

  ${(props) => props.centeredButton && css`
    display: block;
    margin: 0 auto;
  `}
`;

const StyledHorizontalLinesButtonWrapper = styled.div`
  ${flexContainer()};
`;

const StyledDashedHr = styled.hr`
  width: 100%;
  margin-bottom: ${(props) => (props.hasMarginBottom ? '16px' : '0')};
  border: 0;
  border-top: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
`;

const StyledFavoriteAmountButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  grid-gap: 16px;

  @media screen and (max-width: ${MOBILE_SIZE}) { 
    grid-gap: 8px;
  }
`;

const StyledFavoriteAmountButton = styled.button`
  width: 100%;
  min-height: 36px;
  height: auto;
  border: ${(props) => getBorder(props, 'favoriteButton')}; // or 'mainButton'
  border-radius: ${(props) => getBorderRadius(props, 'favoriteButton')};
  border-color: ${(props) => props.theme.favoriteButtonBorderColor};
  background-color: ${({theme}) => theme.favoriteButtonBackgroundColor};
  color: ${({theme}) => theme.favoriteButtonTextColor};
  text-transform: uppercase;
  ${fontStyle('semiBold')};
  cursor: pointer;
`;

const StyledFlexContainer = styled.div`
  ${flexContainer()};
  flex: ${(props) => (props.fullWidth ? '1' : 'auto')};
  grid-gap: ${(props) => (props.hasGap ? '10px' : '0')};
`;

const StyledPayPalButtonWrapper = styled.div`
  width: 100%;
`;

const StyledFavoriteButton = styled.button`
  ${flexContainer()};
  width: 28px;
  height: 28px;
  position: absolute;
  right: -12px;
  border: 0;
  border-radius: 10px 0 0 10px;
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
`;

const StyledFavoriteButtonMobile = styled.button`
  ${flexContainer()};
  width: 24px;
  height: 24px;
  border: 0;
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
`;

const StyledGooglePayWrapper = styled.div`
  .gpay-card-info-container {
    width: 100%;
    border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners
  }
  
  .gpay-card-info-animation-container {
    border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners
    overflow: hidden;
  }
  
  .gpay-button.short, .gpay-button.plain {
    width: 100%;
  }

  .gpay-button {
    border-radius: ${(props) => getBorderRadius(props, 'mainButton')}; // Handles border-radius for all corners
  }
`;

export default StyledButton;
export {
  StyledButtonWithoutBg,
  StyledLinkButton,
  StyledFlexContainer,
  StyledFavoriteAmountButtonWrapper,
  StyledFavoriteAmountButton,
  StyledButtonWithBorder,
  StyledPayPalButtonWrapper,
  StyledFavoriteButton,
  StyledFavoriteButtonMobile,
  StyledHorizontalLinesButtonWrapper,
  StyledGooglePayWrapper,
  StyledDashedHr
};
