import React, {memo, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import i18n from '../configs/i18n';
import ThemeProvider from '../themeProvidere/themeProvider';
import {templateComponents} from '../configs/mainConfig';
import {CashierDetailsContext} from '../contexts/cashierDetailsContext';
import {SelectedPaymentDetailsContext} from '../contexts/selectedPaymentDetailsContext';
import {SimplifiedDataContext} from '../contexts/simplifiedDataContext';
import {PaymentFormCallbacksContext} from '../contexts/paymentFormCallbacksContext';
import {SafetyPayPaymentContext} from '../contexts/safetyPayPaymentContext';
import {PaymentBonusesContext} from '../contexts/paymentBonusesContext';

function PaymentsForm({
  templateType,
  deviceType,
  paymentDetails,
  paymentControlsData,
  paymentBalances,
  paymentLimits,
  paymentGeneratedCodeInfo,
  setPaymentGeneratedCodeInfo,
  customizationTheme,
  paymentDescription,
  simplifiedData,
  handlePaymentSystemDetailsPage,
  handleSubmitForm,
  translations,
  navigate,
  backToListHandler,
  cashierDetails,
  applePaySession,
  handleApplePayClick,
  baseUrl,
  alertContext,
  isCurrencySymbol,
  payPalTransactionData,
  googlePayTransactionData,
  merchantUserMultistepControls,
  setPaymentControlsData,
  setIsLoading,
  isDefaultAvailable,
  onPaymentDefaultStatusUpdateHandler,
  getVerificationHistory,
  paymentSources,
  getVerificationFormControls,
  safetyPayContextData,
  selectedBonusId,
  paymentBonuses,
  setSelectedBonusId
}) {
  useEffect(() => {
    if (cashierDetails?.language && Object.keys(translations)) {
      i18n.addResourceBundle(cashierDetails?.language, 'translation', translations, true, true);
      i18n.changeLanguage(cashierDetails?.language);
    }
  }, [cashierDetails?.language, translations]);

  const formCallbacksMemoized = useMemo(() => ({
    handleSubmitForm,
    handlePaymentSystemDetailsPage,
    navigate,
    backToListHandler,
    handleApplePayClick,
    merchantUserMultistepControls,
    setPaymentControlsData,
    setIsLoading,
    getVerificationHistory,
    getVerificationFormControls
  }), [
    handleSubmitForm,
    handlePaymentSystemDetailsPage,
    navigate,
    backToListHandler,
    handleApplePayClick,
    merchantUserMultistepControls,
    setPaymentControlsData,
    setIsLoading,
    getVerificationHistory,
    getVerificationFormControls
  ]);

  const bonusDataMemoized = useMemo(() => (
    {selectedBonusId, paymentBonuses, setSelectedBonusId}
  ), [selectedBonusId, paymentBonuses, setSelectedBonusId]);

  const DynamicComponent = templateComponents[templateType]?.[deviceType]?.FormComponent;

  if (DynamicComponent) {
    return (
      <ThemeProvider theme={customizationTheme}>
        <CashierDetailsContext.Provider value={cashierDetails}>
          <SimplifiedDataContext.Provider value={simplifiedData}>
            <PaymentFormCallbacksContext.Provider value={formCallbacksMemoized}>
              <SelectedPaymentDetailsContext.Provider value={paymentDetails}>
                <SafetyPayPaymentContext.Provider value={safetyPayContextData}>
                  <PaymentBonusesContext.Provider value={bonusDataMemoized}>
                    <DynamicComponent
                      paymentBalances={paymentBalances}
                      paymentLimits={paymentLimits}
                      setPaymentGeneratedCodeInfo={setPaymentGeneratedCodeInfo}
                      paymentGeneratedCodeInfo={paymentGeneratedCodeInfo}
                      paymentDescription={paymentDescription}
                      applePaySession={applePaySession}
                      alertContext={alertContext}
                      baseUrl={baseUrl}
                      isCurrencySymbol={isCurrencySymbol}
                      translations={translations}
                      payPalTransactionData={payPalTransactionData}
                      googlePayTransactionData={googlePayTransactionData}
                      isDefaultAvailable={isDefaultAvailable}
                      onPaymentDefaultStatusUpdateHandler={onPaymentDefaultStatusUpdateHandler}
                      deviceType={deviceType}
                      paymentSources={paymentSources}
                      paymentControlsData={paymentControlsData}
                    />
                  </PaymentBonusesContext.Provider>
                </SafetyPayPaymentContext.Provider>
              </SelectedPaymentDetailsContext.Provider>
            </PaymentFormCallbacksContext.Provider>
          </SimplifiedDataContext.Provider>
        </CashierDetailsContext.Provider>
      </ThemeProvider>
    );
  }

  return null;
}

PaymentsForm.defaultProps = {
  deviceType: null,
  paymentGeneratedCodeInfo: null,
  setPaymentGeneratedCodeInfo: null,
  customizationTheme: null,
  simplifiedData: null,
  handlePaymentSystemDetailsPage: null,
  handleSubmitForm: null,
  navigate: null,
  backToListHandler: null,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  merchantUserMultistepControls: null,
  setPaymentControlsData: null,
  setIsLoading: null,
  paymentBalances: null,
  paymentLimits: null,
  isDefaultAvailable: false,
  onPaymentDefaultStatusUpdateHandler: null,
  paymentSources: null,
  getVerificationHistory: null,
  getVerificationFormControls: null,
  safetyPayContextData: {},
  selectedBonusId: null,
  paymentBonuses: []
};

PaymentsForm.propTypes = {
  templateType: PropTypes.number.isRequired,
  deviceType: PropTypes.number,
  paymentDetails: PropTypes.object.isRequired,
  paymentControlsData: PropTypes.object.isRequired,
  paymentGeneratedCodeInfo: PropTypes.array,
  setPaymentGeneratedCodeInfo: PropTypes.func,
  customizationTheme: PropTypes.object,
  paymentDescription: PropTypes.object.isRequired,
  simplifiedData: PropTypes.array,
  handlePaymentSystemDetailsPage: PropTypes.func,
  handleSubmitForm: PropTypes.func,
  translations: PropTypes.object.isRequired,
  navigate: PropTypes.func,
  backToListHandler: PropTypes.func,
  cashierDetails: PropTypes.object.isRequired,
  applePaySession: PropTypes.bool.isRequired,
  handleApplePayClick: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  merchantUserMultistepControls: PropTypes.func,
  setPaymentControlsData: PropTypes.func,
  setIsLoading: PropTypes.func,
  paymentBalances: PropTypes.object,
  paymentLimits: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  onPaymentDefaultStatusUpdateHandler: PropTypes.func,
  paymentSources: PropTypes.array,
  getVerificationHistory: PropTypes.func,
  getVerificationFormControls: PropTypes.func,
  safetyPayContextData: PropTypes.object,
  selectedBonusId: PropTypes.number,
  paymentBonuses: PropTypes.array,
  setSelectedBonusId: PropTypes.func.isRequired
};

export default memo(PaymentsForm);
