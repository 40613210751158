import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

function PaymentBonusesListItem({
  imageUrl,
  name,
  description,
  isSelected,
  onSelectHandler,
  onCancelHandler
}) {
  const {t} = useTranslation();

  return (
    <div>
      <div>
        <img src={imageUrl} alt="" />
      </div>
      <div>
        <div>
          <h3>{name}</h3>
          <p>{description}</p>
        </div>
        <button type="button" onClick={isSelected ? onCancelHandler : onSelectHandler}>{t(isSelected ? 'cancel' : 'claim')}</button>
      </div>
    </div>
  );
}

PaymentBonusesListItem.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectHandler: PropTypes.func.isRequired,
  onCancelHandler: PropTypes.func.isRequired
};

export default memo(PaymentBonusesListItem);
