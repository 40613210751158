import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

// styled components
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledPaymentHint} from '../paymentsList/templateOne/web/paymentItemWeb.styled';

function BonusPaymentItem({
  id,
  name,
  image,
  disableMethod,
  unavailable,
  hasRedirect,
  hasVerifiedAccount,
  isExpanded,
  onPaymentClickHandler
}) {
  const {t} = useTranslation();

  const mainElement = (
    <div style={{
      width: '60px',
      height: '30px',
      border: '1px solid black',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    >
      <img src={image} alt="" />
      <p style={{fontSize: '10px'}}>{name}</p>
    </div>
  );

  return (
    <div onClick={(e) => onPaymentClickHandler(e, {
      disableMethod,
      paymentSystemId: id,
      unavailable,
      hasRedirect,
      hasVerifiedAccount
    })}
    >
      {
        isExpanded ? (
          <div style={{
            width: '200px',
            height: '40px',
            padding: '5px',
            display: 'flex',
            backgroundColor: 'lightgrey'
          }}
          >
            {mainElement}
            <p>{name}</p>
            {unavailable ? (
              <StyledPaymentHint
                cardHint
                unavailable={unavailable}
              >
                {t(('underMaintenance')?.toLowerCase())}
              </StyledPaymentHint>
            ) : null}
            <StyledIconSmall icon="arrowRight" fontSize="11px" />
          </div>
        ) : mainElement
      }
    </div>
  );
}

BonusPaymentItem.propTypes = {
  disableMethod: PropTypes.bool,
  unavailable: PropTypes.bool,
  hasRedirect: PropTypes.bool,
  hasVerifiedAccount: PropTypes.bool,
  isExpanded: PropTypes.bool,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onPaymentClickHandler: PropTypes.func.isRequired
};

BonusPaymentItem.defaultProps = {
  disableMethod: false,
  unavailable: false,
  hasRedirect: false,
  hasVerifiedAccount: false,
  isExpanded: false
};

export default memo(BonusPaymentItem);
