import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import BonusPaymentItem from '../../../bonusPaymentItem/bonusPaymentItem';
import {calculateBonusExpiration, findMinMaxAmountsForBonus} from '../../../../helpers/common';

function BonusInfoMobile({
  currency,
  bonusInfo,
  navigateToBonusesList,
  handlePaymentSystemDetailsPage
}) {
  const {t} = useTranslation();
  const [seeAllPayments, setSeeAllPayments] = useState(false);

  const {
    mobileImage,
    id,
    name,
    conditions,
    paymentSystems,
    shortDescription,
    description,
    bonusType,
    expirationPeriod
  } = bonusInfo;
  const [minAmount] = findMinMaxAmountsForBonus(conditions);

  const onSeeAllPaymentsToggleHandler = () => {
    setSeeAllPayments((prev) => !prev);
  };

  const onPaymentClickHandler = useCallback((e, {
    disableMethod,
    paymentSystemId,
    unavailable,
    hasRedirect,
    hasVerifiedAccount
  }) => {
    e.preventDefault();
    e.stopPropagation();
    handlePaymentSystemDetailsPage({
      disableMethod,
      paymentSystemId,
      unavailable,
      hasRedirect,
      hasVerifiedAccount
    });
  }, []);

  return (
    <div>
      <button type="button" onClick={navigateToBonusesList}>
        <StyledIconSmall icon="arrowLeft" fontSize="11px" />
        {t('backtobonuslist')}
      </button>
      <h3>Bonus Info Mobile</h3>
      <div />
      <div>
        <div>
          <img src={mobileImage} alt="" />
        </div>
        <h3>{name}</h3>
        <p>{shortDescription}</p>
        <div style={{
          width: '200px',
          height: seeAllPayments || paymentSystems.length < 6 ? 'fit-content' : '200px',
          position: 'relative'
        }}
        >
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            overflow: 'hidden',
            width: '100%',
            height: '100%'
          }}
          >
            {paymentSystems.map((payment) => <BonusPaymentItem key={payment.id} isExpanded {...payment} onPaymentClickHandler={onPaymentClickHandler} />)}
          </div>
          {paymentSystems.length > 5 ? (
            <button
              type="button"
              style={{
                position: 'absolute',
                top: '100%',
                left: '0'
              }}
              onClick={onSeeAllPaymentsToggleHandler}
            >
              <StyledIconSmall icon={seeAllPayments ? 'arrowUp' : 'arrowDown'} fontSize="11px" />
              {t(seeAllPayments ? 'seeLessPayments' : 'seeAllPayments')}
            </button>
          ) : null}
        </div>
        <div style={{marginTop: 100}}>
          <div>
            <p>{description}</p>
          </div>
          <div>
            <div>
              <span>icon</span>
              <span>
                {t('mindepositamount')}
                :
              </span>
              <span>{`${minAmount} ${currency}`}</span>
            </div>
            <div>
              <span>icon</span>
              <span>
                {t('id')}
                :
              </span>
              <span>{id}</span>
            </div>
            <div>
              <span>icon</span>
              <span>
                {t('type')}
                :
              </span>
              <span>{bonusType}</span>
            </div>
            <div>
              <span>icon</span>
              <span>
                {t('expiration')}
                :
              </span>
              <span>{calculateBonusExpiration(expirationPeriod)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BonusInfoMobile.propTypes = {
  currency: PropTypes.string.isRequired,
  bonusInfo: PropTypes.object.isRequired,
  navigateToBonusesList: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default BonusInfoMobile;
