import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PaymentBonusesListItem from './paymentBonusesListItem';

// styled components
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';


function PaymentBonusesListWeb({
  bonusesList,
  selectedBonusId,
  onSelectBonusHandler,
  onCancelBonusHandler,
  onCloseListHandler
}) {
  const {t} = useTranslation();

  return (
    <div style={{marginTop: '50px'}}>
      <div>WEB</div>
      <div>
        <StyledButtonWithoutBg type="button" onClick={onCloseListHandler} isPositionLeft>
          <StyledIconSmall icon="close" fontSize="11px" />
        </StyledButtonWithoutBg>
        <p>{t('chooseyourbonus')}</p>
      </div>
      <div>
        {bonusesList.map(({
          id, shortDescription, name, webImage
        }) => {
          const isSelected = selectedBonusId === id;

          return (
            <PaymentBonusesListItem
              key={id}
              name={name}
              imageUrl={webImage}
              description={shortDescription}
              isSelected={isSelected}
              onSelectHandler={onSelectBonusHandler(id)}
              onCancelHandler={onCancelBonusHandler}
            />
          );
        })}
      </div>
    </div>
  );
}

PaymentBonusesListWeb.propTypes = {
  selectedBonusId: PropTypes.number,
  bonusesList: PropTypes.array.isRequired,
  onCloseListHandler: PropTypes.func.isRequired,
  onSelectBonusHandler: PropTypes.func.isRequired,
  onCancelBonusHandler: PropTypes.func.isRequired
};

PaymentBonusesListWeb.defaultProps = {
  selectedBonusId: null
};

export default memo(PaymentBonusesListWeb);
