import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import i18n from '../configs/i18n';
import ThemeProvider from '../themeProvidere/themeProvider';
import {templateComponents} from '../configs/mainConfig';

function PaymentsList({
  deviceType,
  templateType,
  paymentData,
  customizationTheme,
  handlePaymentSystemDetailsPage,
  translations,
  language,
  currency,
  isCurrencySymbol,
  enableSearch,
  isFavoriteGroup,
  onFavoriteClickHandler,
  hasTabs,
  activeTab,
  setActiveTab,
  isBonusBanner,
  bonusBannerUrl,
  goToBonusHandler
}) {
  useEffect(() => {
    if (language && Object.keys(translations)) {
      i18n.addResourceBundle(language, 'translation', translations, true, true);
      i18n.changeLanguage(language);
    }
  }, [language, translations]);

  const DynamicComponent = templateComponents[templateType]?.[deviceType]?.ListComponent;

  if (DynamicComponent) {
    return (
      <ThemeProvider theme={customizationTheme}>
        <DynamicComponent
          paymentData={paymentData}
          handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
          currency={currency}
          isCurrencySymbol={isCurrencySymbol}
          enableSearch={enableSearch}
          isFavoriteGroup={isFavoriteGroup}
          onFavoriteClickHandler={onFavoriteClickHandler}
          customizationTheme={customizationTheme}
          deviceType={deviceType}
          hasTabs={hasTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          translations={translations}
          isBonusBanner={isBonusBanner}
          bonusBannerUrl={bonusBannerUrl}
          goToBonusHandler={goToBonusHandler}
        />
      </ThemeProvider>
    );
  }

  return null;
}

PaymentsList.defaultProps = {
  deviceType: null,
  templateType: null,
  paymentData: null,
  customizationTheme: null,
  handlePaymentSystemDetailsPage: null,
  translations: {},
  language: 'en',
  currency: null,
  enableSearch: false,
  isFavoriteGroup: false,
  onFavoriteClickHandler: null,
  isBonusBanner: false,
  bonusBannerUrl: null
};

PaymentsList.propTypes = {
  deviceType: PropTypes.number,
  templateType: PropTypes.number,
  paymentData: PropTypes.array,
  customizationTheme: PropTypes.object,
  handlePaymentSystemDetailsPage: PropTypes.func,
  translations: PropTypes.object,
  language: PropTypes.string,
  currency: PropTypes.string,
  enableSearch: PropTypes.bool,
  isFavoriteGroup: PropTypes.bool,
  onFavoriteClickHandler: PropTypes.func,
  isBonusBanner: PropTypes.bool,
  bonusBannerUrl: PropTypes.string,
  hasTabs: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  goToBonusHandler: PropTypes.func.isRequired
};

export default PaymentsList;
