import React, {memo, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import i18n from '../configs/i18n';
import ThemeProvider from '../themeProvidere/themeProvider';
import {templateComponents} from '../configs/mainConfig';
import {generatePaymentDataForBonusList} from '../helpers/common';

function BonusInfo({
  deviceType,
  templateType,
  customizationTheme,
  translations,
  language,
  currency,
  bonusData,
  simplifiedData,
  navigateToBonusesList,
  handlePaymentSystemDetailsPage
}) {
  useEffect(() => {
    if (language && Object.keys(translations)) {
      i18n.addResourceBundle(language, 'translation', translations, true, true);
      i18n.changeLanguage(language);
    }
  }, [language, translations]);

  const DynamicComponent = templateComponents[templateType]?.[deviceType]?.BonusInfoComponent;

  const bonusInfo = useMemo(() => ({...bonusData, paymentSystems: generatePaymentDataForBonusList(bonusData.paymentSystems, simplifiedData)}), [bonusData, simplifiedData]);

  if (DynamicComponent) {
    return (
      <ThemeProvider theme={customizationTheme}>
        <DynamicComponent
          currency={currency}
          bonusInfo={bonusInfo}
          navigateToBonusesList={navigateToBonusesList}
          handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
        />
      </ThemeProvider>
    );
  }

  return null;
}

BonusInfo.defaultProps = {
  deviceType: null,
  templateType: null,
  customizationTheme: null,
  translations: {},
  language: 'en'
};

BonusInfo.propTypes = {
  deviceType: PropTypes.number,
  templateType: PropTypes.number,
  customizationTheme: PropTypes.object,
  translations: PropTypes.object,
  language: PropTypes.string,
  currency: PropTypes.string.isRequired,
  bonusData: PropTypes.object.isRequired,
  simplifiedData: PropTypes.array.isRequired,
  navigateToBonusesList: PropTypes.func.isRequired,
  handlePaymentSystemDetailsPage: PropTypes.func.isRequired
};

export default memo(BonusInfo);
