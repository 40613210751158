import axios from 'axios';
import {computeSha256HashFromString} from '../helpers/common';

export const getDropdownListOptions = async({
  url,
  setOptions,
  setSelected,
  amount,
  siteId,
  userId,
  paymentSystemId,
  currency,
  country,
  alertContext,
  languageId,
  isOffline,
  platformPaymentSystemId,
  token
}) => {
  if (isOffline || Number(paymentSystemId) === 1917) {
    const concatenatedString = `${siteId}${platformPaymentSystemId}${userId}${languageId}`;
    const inputHash = computeSha256HashFromString(concatenatedString);

    axios
      .get(`${url}/${siteId}/${platformPaymentSystemId}/${userId}/${languageId}`, {
        headers: {
          token,
          Authorization: inputHash
        }
      })
      .then((response) => {
        const formattedOptions = response?.data?.map((option) => ({
          ...option,
          value: option?.id || option?.code,
          label: option.name,
          forVerificationFormControls: option.forVerificationFormControls
        })) || [];
        setOptions(formattedOptions);
        setSelected(formattedOptions[0]?.value);
      })
      .catch((error) => alertContext?.error(error));
  } else {
    const updatedUrl = url.includes('/{0}') ? url.replace('/{0}', `/${amount}`) : url;

    axios
      .get(updatedUrl, {
        headers: {
          siteId,
          userId,
          paymentSystemId
        },
        params: {
          siteId,
          paymentSystem: paymentSystemId,
          userId,
          countryCode: country,
          currency,
          languageISO: languageId,
          token
        }
      })
      .then((response) => {
        const formattedOptions = response?.data?.map((option) => ({
          ...option,
          value: option?.id || option?.code,
          label: option.name
        })) || [];

        if (formattedOptions?.length === 0) {
          alertContext?.error('No Options Available');
        }

        setOptions(formattedOptions);
        setSelected(formattedOptions[0]?.value);
      })
      .catch((error) => alertContext?.error(error));
  }
};
