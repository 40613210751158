import instance from './apiService';
import Config from '../configs/mainConfig';
import {PAYMENTS_IDS} from '../constants/common';

export const getPaymentSystemByMerchantUser = ({
  setPaymentSystemByMerchantUser,
  siteId,
  userId,
  operationType,
  language,
  currency,
  country,
  token,
  setIsLoading,
  alertContext,
  applePaySession,
  navigateToDefault = null
}) => {
  setIsLoading(true);
  return instance.get(
    `${Config.apiURLs.getPaymentSystemByMerchantUser}/${siteId}/${userId}/${operationType}/${language}/${country}/${currency}/${token}`,
    {
      param: {
        siteId,
        userId,
        operationType,
        language,
        country,
        currency,
        token
      }
    }
  )
    .then((response) => {
      const payments = (applePaySession ? [response.data][0]?.paymentMethods : [response.data][0]?.paymentMethods?.map((item) => (
        {
          ...item,
          cashierPaymentSystem: item.cashierPaymentSystem?.filter((system) => system.paymentSystemId !== PAYMENTS_IDS.APPLE_PAY_ID)
        })))?.filter((payment) => payment?.cashierPaymentSystem?.length > 0);
      [response.data][0].paymentMethods = payments;

      if (typeof navigateToDefault === 'function') {
        payments?.some((payment) => payment?.cashierPaymentSystem?.some(({
          isDefault,
          disableMethod,
          unavailable,
          hasVerifiedAccount
        }) => {
          if (isDefault && hasVerifiedAccount && !disableMethod && !unavailable) {
            navigateToDefault(true);
            return true;
          }
          return false;
        }));
      }
      setPaymentSystemByMerchantUser([response.data][0]);

      return [response.data][0].isBonusBanner;
    })
    .catch((error) => alertContext.error(error.response.data));
};
