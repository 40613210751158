import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import BonusPaymentItem from '../bonusPaymentItem/bonusPaymentItem';

function BonusSecondaryPaymentSystems({
  isMobile,
  secondaryPayments,
  onPaymentClickHandler,
  navigateToBonusInfoHandler
}) {
  const [showOptions, setShowOptions] = useState(false);

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  return (
    <div
      style={{
        width: '50px',
        height: '30px',
        border: '1px solid black',
        position: 'relative'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%'
        }}
        onClick={isMobile ? navigateToBonusInfoHandler : onClickHandler}
      >
        +
        {secondaryPayments.length}
      </div>
      {showOptions && !isMobile ? (
        <div style={{
          width: 'fit-content',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '100%',
          right: 0
        }}
        >
          {secondaryPayments.map(({
            id,
            name,
            image
          }) => (
            <BonusPaymentItem
              key={id}
              id={id}
              name={name}
              image={image}
              onPaymentClickHandler={onPaymentClickHandler}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

BonusSecondaryPaymentSystems.propTypes = {
  isMobile: PropTypes.bool,
  navigateToBonusInfoHandler: PropTypes.func,
  secondaryPayments: PropTypes.array.isRequired,
  onPaymentClickHandler: PropTypes.func.isRequired
};

BonusSecondaryPaymentSystems.defaultProps = {
  isMobile: false,
  navigateToBonusInfoHandler: null
};

export default memo(BonusSecondaryPaymentSystems);
