import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getControlPropertyDescriptions, getControlPropertyValues} from '../../../helpers/common';
import {CashierDetailsContext} from '../../../contexts/cashierDetailsContext';
import {PaymentFormCallbacksContext} from '../../../contexts/paymentFormCallbacksContext';
import {SelectedPaymentDetailsContext} from '../../../contexts/selectedPaymentDetailsContext';
import {PAYMENT_CONTROL_TYPE} from '../../../constants/formControlType';
import {DEVICE_TYPE, SAFETY_PAY_IDS} from '../../../constants/common';
import {MOBILE_CONTROLS_MAPPING, WEB_CONTROLS_MAPPING} from '../../../configs/mainConfig';
import {
  TextBoxField,
  LabelField,
  DynamicLabelField,
  ButtonWithMultiUrl,
  NewDynamicDropdownField,
  DynamicDropdownField,
  BankAmountListField,
  StatusMessageField,
  CheckboxField,
  ButtonField,
  QrCodeField,
  ApplePayButtonField,
  GooglePayButtonField,
  PayPalButtonField,
  DocumentUploadField,
  BackToFormButton,
  SelectField,
  NewSelectField,
  HiddenField,
  SafetyPayGuideAndButtonField,
  DoubleField, PaymentBonusField
} from './controlsType/controlsType';
import {BonusCancellationIcon} from '../../../assets/icons';

/* Styled Components*/
import {StyledWrapperMapping} from '../../../helpers/styled';
import {SafetyPayPaymentContext} from '../../../contexts/safetyPayPaymentContext';
import StyledButton from '../../../styledComponents/button/button.styled';
import {
  StyledBonusSectionWrapper, StyledButtonWrapper,
  StyledSectionHeading,
  StyledSectionIconWrapper, StyledSectionInfoWrapper,
  StyledSectionParagraph
} from './web/formWeb.styled';

const RenderPaymentForm = function(props) {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    resetForm,
    handleSubmit,
    controls,
    formattedControls,
    setFieldValue,
    alertContext,
    setSelectedValue,
    selectedValue,
    handlePreviousValue,
    favoriteAmounts,
    autoGeneratedForm,
    isOffline,
    payPalTransactionData,
    googlePayTransactionData,
    deviceType,
    isFileUploaded,
    setUploadedFiles,
    foundDynamicControlGroupItems,
    currency,
    isCurrencySymbol,
    hasVerificationHistory,
    showBonusCancellationWarning,
    onCancelWithdrawalHandler,
    cancelBonusAndProceedWithdrawalHandler
  } = props;

  const {t} = useTranslation();

  const getControlsMapping = () => (deviceType === DEVICE_TYPE.MOBILE ? MOBILE_CONTROLS_MAPPING : WEB_CONTROLS_MAPPING);

  const isMobile = deviceType === DEVICE_TYPE.MOBILE;

  const {
    handleApplePayClick,
    merchantUserMultistepControls,
    setPaymentControlsData,
    setIsLoading
  } = useContext(PaymentFormCallbacksContext);
  const {language} = useContext(CashierDetailsContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const paymentSystemDetail = useContext(SelectedPaymentDetailsContext);
  const {resetTransactionDataHandler} = useContext(SafetyPayPaymentContext);
  const minMax = paymentSystemDetail?.min && paymentSystemDetail?.max ? {
    min: paymentSystemDetail.min,
    max: paymentSystemDetail.max
  } : null;

  const requiredFields = useMemo(
    () => controls?.filter(({fieldControls}) => fieldControls?.find(
      (item) => item?.controlPropertyTypeName === 'required' && item?.value === 'true'
    ))
      ?.map((item) => item?.key),
    [controls]
  );

  const disableSubmit = useMemo(
    () => requiredFields?.some((field) => {
      if (field === 'UploadDocument ') {
        return !isFileUploaded;
      } if (field === 'amount' && minMax) {
        return (!values[field] || values[field] < minMax.min || values[field] > minMax.max);
      }
      return !values[field];
    }) || Object.keys(errors)?.length > 0,
    [requiredFields, values, isFileUploaded, minMax, errors]
  );

  const controlsMapping = getControlsMapping();

  const renderField = (control) => {
    const ControlComponent = controlsMapping[control.controlTypeId];
    if (!ControlComponent) return null;

    const commonProps = {
      id: control?.id,
      name: control?.key,
      error: touched[control.key] && errors[control.key],
      value: values[control.key] || '',
      onChange: handleChange,
      onBlur: handleBlur,
      fieldControl: formattedControls[control.key],
      getControlPropertyDescriptions: getControlPropertyDescriptions(
        control.fieldControls,
        language
      )
    };

    const showVerificationHistoryIcon = getControlPropertyValues(control.fieldControls, language).withverificationhistory ?? false;

    // const additionalProps = {};

    switch (control.controlTypeId) {
      case PAYMENT_CONTROL_TYPE.TEXT_BOX:
        return (
          <TextBoxField
            {...commonProps}
            minMax={minMax}
            favoriteAmounts={favoriteAmounts}
            isMobile={isMobile}
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
            hasVerificationHistory={hasVerificationHistory}
          />
        );
      case PAYMENT_CONTROL_TYPE.LABEL:
        return (
          <LabelField
            {...commonProps}
            label={control.name}
          />
        );
      case PAYMENT_CONTROL_TYPE.DYNAMIC_LABEL:
        return (
          <DynamicLabelField
            {...commonProps}
            type={control.type}
            handleApplePayClick={handleApplePayClick}
          />
        );
      case PAYMENT_CONTROL_TYPE.DYNAMIC_DROPDOWN:
        return (
          <DynamicDropdownField
            id={control.key}
            name={control.key}
            value={values[control.key] || ''}
            error={touched[control.key] && errors[control.key]}
            fieldControl={formattedControls[control.key]}
            setFieldValue={setFieldValue}
            getControlPropertyDescriptions={getControlPropertyDescriptions(
              control.fieldControls,
              language
            )}
            isOffline={isOffline}
            // onChange={handleChange}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.NEW_DYNAMIC_LIST:
        return (
          <NewDynamicDropdownField
            id={control.key}
            name={control.key}
            value={values[control.key] || ''}
            error={touched[control.key] && errors[control.key]}
            fieldControl={formattedControls[control.key]}
            setFieldValue={setFieldValue}
            getControlPropertyDescriptions={getControlPropertyDescriptions(
              control.fieldControls,
              language
            )}
            isOffline={isOffline}
            // onChange={handleChange}
            alertContext={alertContext}
            isMobile={isMobile}
            showVerificationHistoryIcon={showVerificationHistoryIcon}
            hasVerificationHistory={hasVerificationHistory}
          />
        );
      case PAYMENT_CONTROL_TYPE.BANK_AMOUNT_LIST:
        return (
          <BankAmountListField
            {...commonProps}
            type="text"
            label={control.name}
            placeholder={control.name}
            validationError={touched[control.key] && errors[control.key]}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.SELECT:
        return (
          <SelectField
            id={control.key}
            name={control.key}
            error={touched[control.key] && errors[control.key]}
            value={values[control.key] || selectedValue}
            setFieldValue={setFieldValue}
            fieldControl={formattedControls[control.key]}
            getControlPropertyDescriptions={getControlPropertyDescriptions(
              control.fieldControls,
              language
            )}
            getSelectedValue={(value) => {
              setSelectedOption(value);
              handlePreviousValue({[control.key]: JSON.stringify(value)});
              setFieldValue(control.key, selectedValue);
            }}
            selectedValue={selectedValue}
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
          />
        );
      case PAYMENT_CONTROL_TYPE.NEW_STATIC_LIST:
        return (
          <NewSelectField
            id={control.key}
            name={control.key}
            error={touched[control.key] && errors[control.key]}
            value={values[control.key] || selectedValue}
            setFieldValue={setFieldValue}
            fieldControl={formattedControls[control.key]}
            getControlPropertyDescriptions={getControlPropertyDescriptions(
              control.fieldControls,
              language
            )}
            getSelectedValue={(value) => {
              setSelectedOption(value);
              handlePreviousValue({[control.key]: JSON.stringify(value)});
              setFieldValue(control.key, selectedValue);
            }}
            selectedValue={selectedValue}
            isMobile={isMobile}
            hasVerificationHistory={hasVerificationHistory}
          />
        );
      case PAYMENT_CONTROL_TYPE.BUTTON:
      case PAYMENT_CONTROL_TYPE.BUTTON_WIDTH_URL:
        return (
          <ButtonField
            {...commonProps}
            type={control.type}
            disabled={disableSubmit}
          />
        );
      case PAYMENT_CONTROL_TYPE.APPLE_PAY_BUTTON:
        return (
          <ApplePayButtonField
            {...commonProps}
            type={control.type}
            handleApplePayClick={handleApplePayClick}
          />
        );
      case PAYMENT_CONTROL_TYPE.BUTTON_WITH_MULTI_URL:
        return (
          <ButtonWithMultiUrl
            {...commonProps}
            type={control.type}
            handleApplePayClick={handleApplePayClick}
            disabled={disableSubmit}
            merchantUserMultistepControls={merchantUserMultistepControls}
            setPaymentControlsData={setPaymentControlsData}
            alertContext={alertContext}
            setIsLoading={setIsLoading}
          />
        );
      case PAYMENT_CONTROL_TYPE.STATUS_MESSAGE:
        return (
          <StatusMessageField
            {...commonProps}
            label={control.name}
            isMobile={isMobile}
          />
        );
      case PAYMENT_CONTROL_TYPE.QRCODE:
        return <QrCodeField {...commonProps} />;
      case PAYMENT_CONTROL_TYPE.CHECKBOX:
        return (
          <CheckboxField
            {...commonProps}
            type="checkbox"
          />
        );
      case PAYMENT_CONTROL_TYPE.PAYPAL_BUTTON:
        return (
          <PayPalButtonField
            {...commonProps}
            resetForm={resetForm}
            amountValue={values?.amount}
            alertContext={alertContext}
            payPalTransactionData={payPalTransactionData}
            merchantUserMultistepControls={merchantUserMultistepControls}
            setPaymentControlsData={setPaymentControlsData}
            setIsLoading={setIsLoading}
          />
        );
      case PAYMENT_CONTROL_TYPE.GOOGLE_PAY_BUTTON:
        return (
          <GooglePayButtonField
            {...commonProps}
            amountValue={values?.amount}
            resetForm={resetForm}
            alertContext={alertContext}
            googlePayTransactionData={googlePayTransactionData}
            merchantUserMultistepControls={merchantUserMultistepControls}
            setPaymentControlsData={setPaymentControlsData}
            setIsLoading={setIsLoading}
          />
        );
      case PAYMENT_CONTROL_TYPE.UPLOAD_DOCUMENT:
        return (
          <DocumentUploadField
            {...commonProps}
            name={control.name}
            setFile={setUploadedFiles}
            controlProperties={getControlPropertyValues(control.fieldControls, language)}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.BACK_TO_FORM:
        return (
          <BackToFormButton additionalCallback={SAFETY_PAY_IDS.indexOf(paymentSystemDetail.paymentSystemId) > -1 ? resetTransactionDataHandler : null} />
        );
      case PAYMENT_CONTROL_TYPE.HIDDEN:
        return (
          <HiddenField
            {...commonProps}
            type={control.type}
            handleSubmit={handleSubmit}
          />
        );
      case PAYMENT_CONTROL_TYPE.SAFETY_PAY_GUIDE_AND_BUTTON:
        return (
          <SafetyPayGuideAndButtonField
            {...commonProps}
            isMobile={isMobile}
            alertContext={alertContext}
          />
        );
      case PAYMENT_CONTROL_TYPE.DOUBLE_FIELD:
        return (
          <DoubleField
            {...commonProps}
            getControlPropertyDescriptions={getControlPropertyDescriptions(
              control.fieldControls,
              language
            )}
          />
        );
      case PAYMENT_CONTROL_TYPE.PAYMENT_BONUS_FIELD:
        return (
          <PaymentBonusField
            isMobile={isMobile}
            amount={!!values.amount ? Number(values.amount) : 0}
            currency={currency}
            isCurrencySymbol={isCurrencySymbol}
          />
        );
      default:
        return null;
    }

    // return <ControlComponent {...commonProps} {...additionalProps} />;
  };

  useEffect(() => {
    if (selectedOption && Object.keys(selectedOption).length && foundDynamicControlGroupItems) {
      setSelectedValue(selectedOption);
    }
  }, [selectedOption, foundDynamicControlGroupItems]);

  const StyledWrapper = StyledWrapperMapping[deviceType];

  return (
    <StyledWrapper
      autoGeneratedForm={autoGeneratedForm}
      onSubmit={handleSubmit}
    >
      {showBonusCancellationWarning ? (
        <StyledBonusSectionWrapper
          isMobile={isMobile}
        >
          <StyledSectionIconWrapper>
            <BonusCancellationIcon />
          </StyledSectionIconWrapper>
          <StyledSectionInfoWrapper>
            <StyledSectionHeading>
              {t('bonuscancellationheding')}
            </StyledSectionHeading>
            <StyledSectionParagraph>
              {t('bonuscancellationexplanation')}
            </StyledSectionParagraph>
          </StyledSectionInfoWrapper>
          <StyledButtonWrapper spaceBetween>
            <StyledButton
              isMobile={isMobile}
              secondary
              type="button"
              onClick={onCancelWithdrawalHandler}
            >
              {t('cancel')}
            </StyledButton>
            <StyledButton type="button" onClick={cancelBonusAndProceedWithdrawalHandler}>{t('proceedandcancel')}</StyledButton>
          </StyledButtonWrapper>
        </StyledBonusSectionWrapper>
      ) : controls?.map((control) => renderField(control))}
    </StyledWrapper>
  );
};
RenderPaymentForm.defaultProps = {
  autoGeneratedForm: false,
  isOffline: true,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  isFileUploaded: false,
  hasVerificationHistory: false,
  showBonusCancellationWarning: false,
  onCancelWithdrawalHandler: null,
  cancelBonusAndProceedWithdrawalHandler: null
};

RenderPaymentForm.propTypes = {
  deviceType: PropTypes.number.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  controls: PropTypes.array.isRequired,
  formattedControls: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  handlePreviousValue: PropTypes.func.isRequired,
  favoriteAmounts: PropTypes.array.isRequired,
  resetForm: PropTypes.func.isRequired,
  autoGeneratedForm: PropTypes.bool,
  isOffline: PropTypes.bool,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  setUploadedFiles: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool,
  foundDynamicControlGroupItems: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  hasVerificationHistory: PropTypes.bool,
  showBonusCancellationWarning: PropTypes.bool,
  onCancelWithdrawalHandler: PropTypes.func,
  cancelBonusAndProceedWithdrawalHandler: PropTypes.func
};

export default memo(RenderPaymentForm);
